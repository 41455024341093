<template>
  <BaseElSelect
    v-model="syncModel"
    :multiple="multiple"
    value-key="id"
    collapse-tags
    :placeholder="showAll? '請選擇服務': '搜尋服務'"
    no-data-text="暫無數據"
    @change="$emit('change', serviceCategoryList)"
  >
    <BaseElSelectOption
      v-for="item in serviceCategoryList"
      :key="item.id"
      :label="item.name"
      :value="item"
    />
  </BaseElSelect>
</template>

<script>
import { GetServiceCategory, GetServiceCategoryCount } from '@/api/serviceCategory'
import { mapGetters } from 'vuex'
export default {
  name: 'ServiceSelect',
  props: ['model', 'multiple', 'unitId', 'showAll'],
  watch: {
    unitId () {
      this.findServiceUnit()
    },
  },
  computed: {
    ...mapGetters([
      'shop',
    ]),
    syncModel: {
      get () {
        return this.model
      },
      set (data) {
        this.$emit('update:model', data)
      },
    },
  },

  data: () => ({
    serviceCategoryList: [],
  }),

  async mounted () {
    if (this.showAll) {
      await this.getAllServiceCategory()
      return
    }
    await this.getServiceCategory()
  },

  methods: {
    async getAllServiceCategory () {
      try {
        let max = await this.getServiceCategoryCount()
        max = max.data.count
        const options = {
          shopId: this.shop,
          start: 0,
          limit: 100,
        }
        const res = await GetServiceCategory(options)
        console.log(res)
        this.serviceCategoryList = res
        while (this.serviceCategoryList.length < max) {
          options.start += options.limit
          const res = await GetServiceCategory(options)
          this.serviceCategoryList.push(...res)
          console.log('serviceCategoryList', this.serviceCategoryList.length)
        }
      } catch (error) {
        console.log(error)
      }
    },

    async getServiceCategoryCount () {
      try {
        return await GetServiceCategoryCount({ shopId: this.shop })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
